import React, { createContext, useEffect, useState } from 'react'
import { useCurrentTenantApi } from '@/features/states/apiClient/queries'
import { SingleTenantModel } from '@/features/domains/tenants/_tenant_name@string/singleTenantModel'
import { useCanCallPrivateApi } from '@/features/states/auth/authQueries'
import Authorized from '@/components/middlewares/Authorized'

type GlobalTenantProviderProps = {
  children: React.ReactNode
}

const initState = new SingleTenantModel({
  id: 0,
  name: 'string',
  system_id: 'string',
  tel: 'string',
  industry_kind: 0,
  contractor_first_name: 'string',
  contractor_last_name: 'string',
  contact_email: 'string',
  callcenter_tel: 'string',
  zip: 'string',
  prefecture: 'string',
  city: 'string',
  address: 'string',
  building: 'string',
  is_closed: true,
  version: 0,
  created_at: '2021-07-26T07:11:17.606Z',
  updated_at: '2021-07-26T07:11:17.606Z',
  brand_name: 'string',
  web_reservation_url: 'string',
  setting: {
    id: 0,
    tenant_id: 0,
    enable_duty_management: true,
    enable_machine_management: true,
    enable_web_reservation: true,
    enable_interview: true,
    enable_digital_ticket: true,
    enable_document: true,
    enable_sms: true,
    enable_line: true,
    enable_job: true,
    enable_ticket_number_numbering: true,
    enable_online_payment: true,
    enable_orca_integration: true,
    enable_ekarte: true,
    enable_report: true,
    enable_enquete: true,
    enable_online_examination: true,
    created_at: '2021-09-09T06:03:03.559Z',
    updated_at: '2021-09-09T06:03:03.559Z',
  },
})

export const GlobalTenantContext = createContext<SingleTenantModel>(initState)

export default function GlobalTenantProvider({
  children,
}: GlobalTenantProviderProps) {
  const api = useCurrentTenantApi()
  const { isAuthStateSubscribed, isPublicPath } = useCanCallPrivateApi()

  const [value, setValue] = useState<SingleTenantModel>(initState)

  useEffect(() => {
    const getValue = () => {
      return new Promise<void>((resolve) => {
        api.$get().then((response) => {
          setValue(new SingleTenantModel(response))
          resolve()
        })
      })
    }

    if (isPublicPath) {
      return
    }

    if (!isAuthStateSubscribed) {
      return
    }

    getValue()
  }, [isPublicPath, isAuthStateSubscribed, api])

  return (
    <Authorized>
      <GlobalTenantContext.Provider value={value}>
        {children}
      </GlobalTenantContext.Provider>
    </Authorized>
  )
}
