import type * as Types from '@/lib/api-client/aspida/@types'

export class SingleTenantModel {
  private response: Types.TenantShowResponseV1

  constructor(response: Types.TenantShowResponseV1) {
    this.response = response
  }

  public getResponse() {
    return this.response
  }

  public getSetting() {
    return this.response.setting
  }

  public isAllowed(featureType: FeatureType) {
    const feature = this.getAllowFeatureList().find(
      (feature) => feature.type === featureType
    )

    return feature ? feature.isAllowed : false
  }

  private getAllowFeatureList() {
    return createFeatureSettings(this.response.setting)
  }
}

export type FeatureType =
  | 'ekarte'
  | 'report'
  | 'document'
  | 'dutyManagement'
  | 'webReservation'
  | 'interview'
  | 'digitalTicket'
  | 'enquete'
  | 'onlineExamination'
  | 'onlinePayment'

type FeatureSetting = {
  type: FeatureType
  isAllowed: boolean
}
const createFeatureSettings = (
  setting: Types.TenantSettingEntity
): FeatureSetting[] => {
  return [
    { type: 'ekarte', isAllowed: setting.enable_ekarte },
    { type: 'report', isAllowed: setting.enable_report },
    { type: 'document', isAllowed: setting.enable_document },
    { type: 'dutyManagement', isAllowed: setting.enable_duty_management },
    { type: 'webReservation', isAllowed: setting.enable_web_reservation },
    { type: 'interview', isAllowed: setting.enable_interview },
    { type: 'digitalTicket', isAllowed: setting.enable_digital_ticket },
    { type: 'enquete', isAllowed: setting.enable_enquete },
    { type: 'onlineExamination', isAllowed: setting.enable_online_examination },
    { type: 'onlinePayment', isAllowed: setting.enable_online_payment },
  ]
}
