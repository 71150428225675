import styles from './style.module.scss'
import { useCloseSnackbar } from '@/features/states/snackbar/snackbarActions'
import { useSnackbarState } from '@/features/states/snackbar/snackbarQueries'
import * as Mui from '@material-ui/core'
import React, { useCallback } from 'react'
import { Alert } from '@material-ui/lab'
import { SnackbarProps } from '@material-ui/core'

// eslint-disable-next-line @typescript-eslint/ban-types
type AppSnackbarProps = {}

export default function AppSnackbar(props: AppSnackbarProps) {
  const { open, message, severity = 'info' } = useSnackbarState()

  const handleCloseSnackbar = useCloseSnackbar()

  const handleOnClose = useCallback(() => {
    handleCloseSnackbar()
  }, [handleCloseSnackbar])

  const onCloseSnackbar: NonNullable<SnackbarProps['onClose']> = (
    event,
    reason
  ) => {
    //snackbar以外を押下した際に閉じられてしまう問題の解決
    //https://github.com/mui-org/material-ui/issues/5184#issuecomment-569418493
    if (reason === 'clickaway') {
      return
    }

    handleOnClose()
  }

  return (
    <Mui.Snackbar
      open={open}
      // エラー以外は3秒経過で消えるように
      autoHideDuration={severity !== 'error' ? 3000 : undefined}
      onClose={severity !== 'error' ? onCloseSnackbar : undefined}
      transitionDuration={{ enter: 300, exit: 1 }}>
      <Alert
        className={styles.snackbarContent}
        variant="standard"
        severity={severity}
        onClose={handleOnClose}>
        {message}
      </Alert>
    </Mui.Snackbar>
  )
}
