import React, { useMemo } from 'react'
import { composeWrappers } from 'react-compose-wrappers'
import DatePickerLocalizationProvider from '@/components/providers/DatePickerLocalizationProvider'
import * as Mui from '@material-ui/core'
import GlobalTenantProvider from '@/components/providers/GlobalState/GlobalTenantProvider'
import GlobalUserProvider from './GlobalState/GlobalUserProvider'
import GlobalTheme from '@/components/themes/GlobalTheme'
import { ModalProvider } from 'react-modal-hook'

type GlobalStateProviderProps = {
  children: React.ReactNode
}

/** ネストが辛いので各Providerをここにまとめる */
export default function ComposeProviders({
  children,
}: GlobalStateProviderProps) {
  const Providers = useMemo(() => {
    return composeWrappers([
      //テナント
      ({ children }) => <GlobalTenantProvider>{children}</GlobalTenantProvider>,
      //ログインユーザー
      ({ children }) => <GlobalUserProvider>{children}</GlobalUserProvider>,
      //Datepicker
      ({ children }) => (
        <DatePickerLocalizationProvider>
          {children}
        </DatePickerLocalizationProvider>
      ),
      //Material UI Style
      ({ children }) => (
        <Mui.StylesProvider injectFirst>{children}</Mui.StylesProvider>
      ),
      //Material UI GlobalTheme
      ({ children }) => <GlobalTheme>{children}</GlobalTheme>,
      //Modal
      ({ children }) => <ModalProvider>{children}</ModalProvider>,
    ])
  }, [])

  return <Providers>{children}</Providers>
}
