import { LocalizationProvider } from '@material-ui/pickers'
import LuxonUtils from '@material-ui/pickers/adapter/luxon'
import { DateIOFormats } from '@date-io/core/IUtils'

type DatePickerLocalizationProviderProps = {
  children?: React.ReactNode
}

//https://github.com/dmtrKovalenko/date-io/blob/7b6f74e9a75dc6468ab869e77b562390ed57efb7/packages/luxon/src/luxon-utils.ts#L4
const dateFormats: DateIOFormats = {
  dayOfMonth: 'd',
  fullDate: 'DD',
  fullDateWithWeekday: 'DDDD',
  fullDateTime: 'ff',
  fullDateTime12h: 'DD, hh:mm a',
  fullDateTime24h: 'DD, T',
  fullTime: 't',
  fullTime12h: 'hh:mm a',
  fullTime24h: 'HH:mm',
  hours12h: 'hh',
  hours24h: 'HH',
  keyboardDate: 'D日',
  keyboardDateTime: 'D日 t',
  keyboardDateTime12h: 'D hh:mm a',
  keyboardDateTime24h: 'D T',
  minutes: 'mm',
  seconds: 'ss',
  month: 'LLLL月',
  monthAndDate: 'MMMM月 d日',
  monthAndYear: 'yyyy年MM月',
  monthShort: 'MMM月',
  weekday: 'cccc',
  weekdayShort: 'ccc',
  normalDate: 'yyyy年LL月dd日',
  normalDateWithWeekday: 'EEE, MMM d',
  shortDate: 'LL月 d日',
  year: 'yyyy年',
}

/**
 * pickerのadapterを上書きできる
 * https://material-ui-pickers.dev/guides/formats#utils-interface
 *
 */
class LocalizedUtils extends LuxonUtils {
  locale = 'ja'
}

export default function DatePickerLocalizationProvider({
  children,
}: DatePickerLocalizationProviderProps) {
  return (
    <LocalizationProvider
      dateAdapter={LocalizedUtils}
      dateFormats={dateFormats}>
      {children}
    </LocalizationProvider>
  )
}
