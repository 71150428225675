import { AppRecoilSync } from '@/components/providers/AppRecoilSync/AppRecoilSync'
import { RecoilQueryStateSync } from '@/features/states/url/queryState/RecoilQueryStateSync'
import React from 'react'

type ComposeRecoilSyncsProps = {
  children: React.ReactNode
}

/** Recoilの同期系をここにまとめる */
export default function ComposeRecoilSyncs(props: ComposeRecoilSyncsProps) {
  return (
    <RecoilQueryStateSync>
      <AppRecoilSync>{props.children}</AppRecoilSync>
    </RecoilQueryStateSync>
  )
}
