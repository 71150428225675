import { RECOIL_URL_SYNC_KEYS } from '@/components/providers/AppRecoilSync/constants'
import { RecoilURLSyncJSON } from 'recoil-sync'

type Props = {
  children: React.ReactNode
}

export const AppRecoilSync: React.FC<Props> = ({ children }) => {
  return (
    <RecoilURLSyncJSON
      storeKey={RECOIL_URL_SYNC_KEYS.JSON.STORE_KEY}
      location={{
        part: 'queryParams',
        param: RECOIL_URL_SYNC_KEYS.JSON.PARAM_KEY,
      }}>
      <RecoilURLSyncJSON
        storeKey={RECOIL_URL_SYNC_KEYS.DIALOG_V3.STORE_KEY}
        location={{
          part: 'queryParams',
          param: RECOIL_URL_SYNC_KEYS.DIALOG_V3.PARAM_KEY,
        }}>
        {children}
      </RecoilURLSyncJSON>
    </RecoilURLSyncJSON>
  )
}
