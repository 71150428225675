import { AppProps } from 'next/app'
import React from 'react'
import ComposeProviders from '@/components/providers/ComposeProviders'
import AppBackDrop from './organisms/AppBackDrop/AppBackDrop'
import { useAxiosInterceptor } from '@/features/states/apiClient/interceptor/interceptorActions'
import AppSnackbar from './organisms/AppSnackbar/AppSnackbar'
import AppJwtPersister from './organisms/AppJwtPersister/AppJwtPersister'
import BackDropSuspence from '@/components/molecules/BackDropSuspence/BackDropSuspence'

export default function ComponentRoot({ Component, pageProps }: AppProps) {
  //axiosのinterceptorを設定する
  useAxiosInterceptor()

  return (
    <BackDropSuspence>
      <ComposeProviders>
        <AppJwtPersister />
        <AppBackDrop />
        <AppSnackbar />
        <Component {...pageProps} />
      </ComposeProviders>
    </BackDropSuspence>
  )
}
