//global style
import '@/styles/global.scss'

//フルカレンダー関連
import '@fullcalendar/common/main.css'
import '@fullcalendar/timegrid/main.css'
import '@/styles/fullCalendar.scss'

import Head from 'next/head'
import { AppProps } from 'next/app'
import React, { useEffect } from 'react'
import ComponentRoot from '@/components/ComponentRoot'
import { initFirebase } from '@/lib/firebase'
import { RecoilRoot } from 'recoil'
import { ErrorBoundary } from 'react-error-boundary'
import GenericErrorTemplate from '@/components/templates/GenericErrorTemplate/GenericErrorTemplate'
import ComposeRecoilSyncs from '@/components/providers/ComposeRecoilSyncs'
import { useEffectOnce } from '@/lib/hooks/useEffectOnce'
import BackDropSuspence from '@/components/molecules/BackDropSuspence/BackDropSuspence'

// import RecoilDebugger from '@/debugger/recoil/RecoilizeDebugger'

initFirebase()

function MyApp(appProps: AppProps) {
  //https://material-ui.com/guides/server-rendering/#the-client-side
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles)
    }
  }, [])

  useListenUnhandledRejection()

  return (
    <>
      <Head>
        <title>SMARTCRM</title>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
          key="viewport"
        />
      </Head>
      <ErrorBoundary FallbackComponent={GenericErrorTemplate}>
        <RecoilRoot>
          <BackDropSuspence>
            <ComposeRecoilSyncs>
              {/* <RecoilDebugger /> */}
              <ComponentRoot {...appProps} />
            </ComposeRecoilSyncs>
          </BackDropSuspence>
        </RecoilRoot>
      </ErrorBoundary>
    </>
  )
}

const useListenUnhandledRejection = () => {
  useEffectOnce(() => {
    // アプリケーションの挙動として問題ないかはテストで網羅されているので握りつぶす
    // SEE:
    //  sentry.client.config.ts
    //  https://github.com/caloojp-smartcrm/SaaS_management_client/pull/994#discussion_r790370403
    const onUnhandleRejection = (event: PromiseRejectionEvent) => {
      // nothing to do
    }

    window.addEventListener('unhandledrejection', onUnhandleRejection)
    return () => {
      window.removeEventListener('unhandledrejection', onUnhandleRejection)
    }
  })
}

export default MyApp
