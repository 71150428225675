import { AxiosError } from 'axios'
import { Integration, EventProcessor } from '@sentry/types'
import * as NextSentry from '@sentry/nextjs'

const isAxiosError = (error: any): error is AxiosError<Error> => {
  return !!error.isAxiosError
}

const isReportable = (obj: unknown) => {
  // エラーではない場合(文字列など)は通知する
  if (!(obj instanceof Error)) {
    return true
  }

  // axiosエラーではない場合はアプリの実装起因なので通知する
  if (!isAxiosError(obj)) {
    return true
  }

  // ネットワークエラー(VPN切れなど)は通知しない
  const { response } = obj
  if (response === undefined) {
    return false
  }

  // http status codeが400番台のもの(バリデーションエラーなど)を全て通知しても意味がないので通知しない
  // https://github.com/caloojp-smartcrm/SaaS_management_client/pull/994#discussion_r790370403
  const UNKNOWN_HTTP_STATUS = 999
  const { status = UNKNOWN_HTTP_STATUS } = response
  if (400 <= status && status < 500) {
    return false
  }

  return true
}

const axiosEventProcessor: EventProcessor = (event, hint) => {
  if (isReportable(hint.originalException)) {
    return event
  } else {
    return null
  }
}

// https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/integrations/custom/
class CustomAxiosIntegration implements Integration {
  name = 'CustomAxiosIntegration'

  setupOnce(addGlobalEventProcessor: (callback: EventProcessor) => void): void {
    addGlobalEventProcessor(axiosEventProcessor)
  }
}

const createAppSentry = (Sentry: typeof NextSentry) => {
  return {
    ...Sentry,
    createCustomAxiosIntegration: () => {
      return new CustomAxiosIntegration()
    },
  }
}

/** Sentryのラッパー */
export const AppSentry = createAppSentry(NextSentry)
