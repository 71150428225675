import * as Mui from '@material-ui/core'
import styles from './style.module.scss'
import GlobalTheme from '@/components/themes/GlobalTheme'
import { useEffectOnce } from '@/lib/hooks/useEffectOnce'
import { AppSentry } from '@/lib/sentry'
import {
  InternalErrorResponse,
  isUnderMaintenance,
} from '@/features/states/apiClient/interceptor/ResponseErrorInterceptor/responseErrorInterceptorActions'
import MaintenanceTemplate from '@/components/templates/Maintenace/MaintenanceTemplate'

type Props = {
  error: unknown
}

const isInternalError = (error: unknown): error is InternalErrorResponse => {
  return (
    error !== null &&
    typeof error === 'object' &&
    'isAxiosError' in error &&
    error.isAxiosError === true
  )
}

export default function GenericErrorTemplate(props: Props) {
  const { error } = props

  useEffectOnce(() => {
    AppSentry.captureException(error)
  })

  if (isInternalError(error) && isUnderMaintenance(error)) {
    return <MaintenanceTemplate message={error.response?.data?.message} />
  }

  return (
    <GlobalTheme>
      <Mui.Container maxWidth={false} disableGutters={true}>
        <Mui.Box component="header" className={styles.header}>
          <Mui.Box className={styles.headerInner}>
            <Mui.Box>
              <img
                src="/logo_smart_crm_horizontal.svg"
                alt="SMARTCRM"
                width={150}
              />
            </Mui.Box>
          </Mui.Box>
        </Mui.Box>
        <Mui.Box component="main" className={styles.contentBase}>
          <Mui.Paper className={styles.contentInner}>
            <Mui.Box paddingBottom={10}>
              <p className={styles.bold}>予期せぬエラーが発生しました</p>
              <p>
                誠に恐れ入りますが、以下のボタンからページの再読み込みをお願いします。
              </p>
              <p>
                それでも本ページが表示される場合は、お手数おかけしますがサポートへお問い合わせください。
              </p>
            </Mui.Box>

            <Mui.Button
              variant="contained"
              onClick={() => location.reload()}
              color={'primary'}>
              リロードする
            </Mui.Button>
          </Mui.Paper>
        </Mui.Box>
      </Mui.Container>
    </GlobalTheme>
  )
}
