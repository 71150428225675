import React from 'react'
import { usePersistJwt } from '@/features/states/auth/authActions'
import { useInterval } from 'react-use'
import { useEffectOnce } from '@/lib/hooks/useEffectOnce'
import { DateTime } from 'luxon'

/** 10分 */
const TICK = 1000 * 60 * 10

/**
 * 認証を永続化する
 * 1.TICKのタイミングごとにJWTの有効期限を検証する
 * 2.次のTICKで有効期限が切れる場合はJWTを更新する
 * */
export default function AppJwtPersister() {
  const persistJwt = usePersistJwt({ tick: TICK })

  const handler = () => {
    // フォーカスされていない場合はポーリングしない
    if (!document.hasFocus()) {
      return
    }

    persistJwt({ currentDateTime: DateTime.local() })
  }

  // 初回レンダリング
  useEffectOnce(() => {
    handler()
  })

  // バックグラウンドから戻ってきた時のリスナ登録
  useEffectOnce(() => {
    window.addEventListener('focus', handler)

    return () => {
      window.removeEventListener('focus', handler)
    }
  })

  // TICK毎
  useInterval(() => {
    handler()
  }, TICK)

  return <>{null}</>
}
