import Button from '@/components/atoms/Button/Button'
import { aspida } from '@/features/states/apiClient/apiClientState'
import {
  InternalErrorResponse,
  isUnderMaintenance,
} from '@/features/states/apiClient/interceptor/ResponseErrorInterceptor/responseErrorInterceptorActions'
import Router from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import styles from './style.module.scss'

export default function MaintenanceTemplate(props: { message?: string }) {
  const [message, setMessage] = useState<string | undefined>(props.message)
  const DEFAULT_ERROR_MESSAGE =
    '予期せぬ問題が発生しました。\n再度操作しても解消しない場合は、サポートにお問い合わせください。'

  const tenantName = location.pathname.split('/')[1] || ''

  const back = useCallback(() => {
    const redirectUrl = `/${tenantName}`
    if (location.pathname === redirectUrl) {
      location.reload()
      return
    }
    Router.push(redirectUrl)
  }, [tenantName])

  useEffect(() => {
    aspida.api.v1.tenants
      ._tenant_name(tenantName)
      .$get()
      .then(() => back())
      .catch((err: InternalErrorResponse) => {
        const message = isUnderMaintenance(err)
          ? err.response?.data?.message
          : DEFAULT_ERROR_MESSAGE
        setMessage(message)
      })
  }, [back, tenantName])

  return (
    <div className={styles.contentBase}>
      <h2 className={styles.title}>システムメンテナンスのご案内</h2>
      <p className={styles.text}>{message}</p>
      <Button className={styles.backButton} color="primary" onClick={back}>
        戻る
      </Button>
    </div>
  )
}
